textarea.input-validation-error,
input.input-validation-error,
select.input-validation-error {
    color: red !important;
    border: 2px solid red !important;
}

span.field-validation-error {
    color: red;
}

body {
    overflow-y: scroll;
}

.default-margin {
    margin: 1em 2em;
}

header {
    .logo {
        @extend .default-margin;
    }

    .title {
        font-size: xxx-large;
    }

    .user {
        text-align: right;
        @extend .default-margin;
    }

    display: grid;
    grid-template-columns: minmax(max-content, 1fr) auto minmax(max-content, 1fr);
    align-items: center;
}

.navbar-brand {
    font-weight: bolder;
}

ol.crumbs {
    list-style-type: none;
    padding-left: 0;

    .crumb {
        display: inline-block;
    }

    .crumb a::after {
        display: inline-block;
        color: #000;
        content: ">";
        font-size: 80%;
        font-weight: bold;
        padding: 0 3px;
    }
}

.nav-item-selected {
    border-bottom: 1px solid black;
}

.form-label,
.form-check-label,
legend {
    font-weight: 500;
    user-select: none;
}

label.required:after,
legend > span.required:after {
    content: "*";
    color: red;
    margin-left: 4px;
}

.datepicker-multiple[disabled] + .air-datepicker > .air-datepicker--content {
    pointer-events: none;
    cursor: not-allowed;
    user-select: none;
}

.statistics-panel {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0;
}

.loading-spinner {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    left: 50%;
    top: 50%;
    position: relative;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.table-hover-effect {
    tr {
        /* This prevents the bootstrap variable from overriding the row color set by the user */
        --bs-table-bg: transparent;
    }

    tbody > tr {
        transition: background-color ease-out 0.2s;
        cursor: pointer;
    }

    input {
        transition: background-color ease-out 0.2s;
    }

    tbody > tr:hover {
        background-color: #e9ecef;
    }

    &.cultural-management-selection tr {
        background-color: #f0f6ff;
    }

    &.cultural-management-selection > tbody > tr:hover {
        background-color: #d9e6fa;
    }
}

.planning-modal__calender-day-cell {
    width: 100%;

    &.selected {
        background-color: #e9ecef;
    }
}

.planning-modal__calender-date {
    height: 1.2rem;
    padding-bottom: 1px;
}

.planning-modal__number-of-occasions-indicators {
    height: 1.2rem;

    .indicator {
        background: blue;
        border-radius: 5px;
        width: 100%;
        height: 30%;
        margin-bottom: 1px;
    }
}

.slide-alert {
    position: fixed;
    top: 64px;
    left: 50%;
    transform: translate(-50%);
    animation: slide-down 0.1s linear;
}

.slide-out {
    animation: slide-out 0.12s linear;
}

@keyframes slide-down {
    0% {
        margin-top: -1%;
        height: 0;
    }

    80% {
        margin-top: 0.2%;
    }

    100% {
        margin-top: 0;
        height: fit-content;
    }
}

@keyframes slide-out {
    0% {
        margin-top: 0;
        height: fit-content;
        opacity: 100%;
    }

    80% {
        margin-top: -1%;
        height: 0;
        opacity: 0;
    }
}

.accordion-table {
    $border: 1px solid #dee2e6;

    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: $border;
    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;

    th:not(:last-child),
    td:not(:last-child) {
        border-right: $border;
    }

    tr:last-child td {
        border-bottom: none;
    }
}

.hide-input-scroll::-webkit-outer-spin-button,
.hide-input-scroll::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.selection-header {
    display: flex;
    margin: 0 1rem;
    @media (max-width: 1470px) {
        flex-direction: column;
    }

    @media (max-width: 1580px) {
        a {
            font-size: 14px;
        }
    }
}

.selection-overview-table {
    font-size: 14px;
    th {
        white-space: nowrap;
    }
}

.application-selection-color-modal__button {
    $button-diameter: 48px;

    height: $button-diameter;
    width: $button-diameter;
    border-radius: 100%;
    margin: 8px;

    border: none;

    &.selected {
        border: 4px solid black;
    }
}

.planning-modal__calendar-container {
    $rdp-border: 1px solid #dee2e6;

    .rdp {
        --rdp-cell-size: 80px; /* Size of the day cells. */
        --rdp-caption-font-size: 20px; /* Font size for the caption labels. */
    }

    .rdp-head_cell {
        font-size: 16px;
        font-weight: 700;
    }

    .rdp-cell {
        border: $rdp-border;
    }

    .rdp-head {
        border: $rdp-border;
    }
}

table.table {
    margin-bottom: -1px;
}
